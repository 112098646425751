import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import '../styles/App.css';
import '../styles/Contact.css';
import { Footer, Navbar } from './Navbar';

const ReturnPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="row jei34">
                <div className="col-md-7">
                    <h1 className='contact-page-heading'>Return Policy</h1>
                    <br/>
                    <div>
                        <p>At Satvayug Oil, we strive for your complete satisfaction. If you're not happy with your purchase, we offer a hassle-free return policy.</p>
                        <p>Here's what you need to know:</p>
                        <ol>
                            <li className='jei35'>Applicable products are returnable within the applicable return window if you've received them in a condition that is physically damaged, has missing parts or accessories, defective or different from their description on the product detail page on Satvayug.com</li>
                            <li className='jei35'>Return Window: You can return unopened and unused items within 7 days of purchase.</li>
                            <li className='jei35'>
                                Return will be processed only if:
                                <ul>
                                    <li>it is determined that the product was not damaged while in your possession;</li>
                                    <li>the product is not different from what was shipped to you;</li>
                                    <li>the product is returned in original condition</li>
                                </ul>
                            </li>
                            <li className='jei35'>Refunds: We will issue a full refund to the original payment method used for the purchase.</li>
                            <li className='jei35'>Return Shipping: We will collect the parcel free of charge for your convenience.</li>
                        </ol>
                        <p>
                            How to Return an Item:
                        </p>
                        <ol>
                            <li className='jei35'>Contact Us: Please initiate a return request by contacting our customer service team at <a href='mailto:help@satvayug.com'>help@satvayug.com</a>.</li>
                            <li className='jei35'>Return Authorization: You will receive a return authorization number (RAN) and instructions on how to prepare your return shipment.</li>
                            <li className='jei35'>Schedule a Pickup: We will provide you with options to schedule a convenient pickup for your return package.</li>
                        </ol>
                        <p>Exceptions:</p>
                        <ul>
                            <li className='jei35'>We cannot accept returns on opened or used items.</li>
                            <li className='jei35'>We cannot accept returns on items damaged due to misuse or neglect.</li>
                            <li className='jei35'>We cannot accept returns for items purchased more than 30 days ago.</li>
                        </ul>
                        <p>
                            Futhermore, if you have any questions regarding our return policy, please don't hesitate to contact our customer service team. We're always happy to help!
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ReturnPolicy;