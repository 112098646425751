import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import basket_shopping_solid from '../assets/icons/basket-shopping-solid.svg';
import fologo from '../assets/logo.svg';
import logo from '../assets/logo_navbar.svg';
import '../styles/Navbar.css';

import icon_instagram from '../assets/icons/akar-icons_instagram-fill.svg';
import icon_whatsapp from '../assets/icons/icon_wa.svg';
import satvayug_logo from '../assets/img/satvayug-logo.png';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav mx-auto">
                        <a className="nav-link" href="/products">Products</a>
                        <a className="nav-link" href="/about">About</a>
                        <a className="nav-link" href="#">Track Order</a>
                        <a className="nav-link" href="/contact">Contact</a>
                    </div>

                    <div className="navbar-nav ml-auto">
                        <a className="nav-link" href="/cart">
                            <img src={basket_shopping_solid} width={28} height={28} />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <a href="/">
                    <img src={satvayug_logo} className='mi4ea' alt="Satvayug Logo" />
                </a>
                <a href="/">
                    <img src={fologo} className='mi4ex' width="124px" />
                </a>
            </div>
            <div className='right-container'>
                <div className="footer-nav">
                    <span className='quick-links'>Quick Links</span>
                    <ul>
                        <li><a href="/policies/return">Return Policy</a></li>
                        <li><a href="/policies/terms">Terms and Conditions</a></li>
                        <li><a href="/policies/privacy">Privacy Policy</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/track-order">Track Order</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className='hd76'>
                    <div className="footer-contact">
                        <span className='quick-links'>Need Help?</span>
                        <p className='nf79h'>Mail us at - <a href="mailto:help@satvayug.com">help@satvayug.com</a></p>
                    </div>
                    <div className="footer-social">
                        {/*<a href='#'><img src={icon_facebook} width="28px" /></a>*/}
                        <a href='https://www.instagram.com/satvayug/'><img src={icon_instagram} width="28px" /></a>
                        <a href='https://api.whatsapp.com/send?phone=918591476976'><img src={icon_whatsapp} width="28px" /></a>
                    </div>
                    <div className="footer-copyright">
                        <p>&copy; 2024 Satvayug. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export {
    Footer, Navbar
};

