import { signInAnonymously } from 'firebase/auth';
import React from 'react';
import hero_img from '../assets/img/hero-image.png';
import '../styles/App.css';
import { Footer, Navbar } from './Navbar';
import { fireabaseapiurl } from './base/base';
import { firebaseAuth } from './base/firebase';

const ProductCard = ({ product, index }) => (
  <div className='product-card col-md-3' key={index}>
    <img className='card-img' src={product?.document?.fields?.imageURL?.stringValue} alt={product?.document?.fields?.name?.stringValue} />
    <div className='card-details'>
      <h3 className='card-title'>{product?.document?.fields?.name?.stringValue}</h3>
      <p className='card-text'>{product?.document?.fields?.description?.stringValue}</p>
    </div>
  </div>
)

function Home() {
  const [products, setProducts] = React.useState(null);

  const fch = (current_user) => {
    const body = JSON.stringify({
      "structuredQuery": {
        "select": {
          "fields": [
            {
              "fieldPath": "imageURL"
            },
            {
              "fieldPath": "name"
            },
            {
              "fieldPath": "description"
            }
          ]
        },
        "from": [
          {
            "collectionId": "Homepage_Products"
          }
        ]
      }
    })
    console.warn(current_user)
    fetch(fireabaseapiurl + ":runQuery", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + current_user
      },
      body: body
    })
      .then((response) => response.json())
      .then((response) => {
        setProducts(response)
      })
      .catch((error) => console.error(error))
  }

  /*const fch = () => {
    fetch(baseurl + 'api/HomePage_Products/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + apitoken
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setProducts(response);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        //setisRefreshing(false)
      });
  }*/

  React.useEffect(() => {
    signInAnonymously(firebaseAuth)
      .then((x) => {
        // Signed in..
        x.user.getIdToken().then((token) => {
          fch(token);
        })

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });

  }, []);

  return (
    <div>
      <Navbar />
      <section className='hero'>
        <div className='row'>
          <div className='col-md-7'>
            <span className='hero-heading'>Unveiling the Purity of Flavor: Wood-Pressed Oils</span>
            <span className='hero-subheading'>Experience the difference: Richer taste, unrivaled health benefits, and a touch of tradition in every drop.</span>
            <span className='hero-text'>
              <p>Elevate your cooking beyond the ordinary with the exquisite taste and remarkable health benefits of wood-pressed oils. Our artisanal process, using time-honored methods and handcrafted presses, gently extracts oil from the finest seeds and nuts.</p>
              <br />
              <p>The result? Pure, unadulterated oil bursting with flavor and brimming with essential nutrients. Unlike commercially processed oils, wood-pressed oils retain a higher concentration of vitamins, antioxidants, and good fats – a healthy boost for every dish.</p>
            </span>
            <div className='hero-button-container'>
              <button className='hero-button' onClick={() => window.location.href = 'products/'}>Shop Now</button>
            </div>
          </div>
          <div className='col-md-5 hero-image-container'>
            <img className='hero-image' src={hero_img} width='100%' />
          </div>
        </div>
      </section>
      <section className='section-2'>
        <span className='section-title'>Can Your Oil Do More? Find Out with <span className='color-green'>Satvayug</span></span>
        <span className='section-text'>
          Unleash the full flavor and potential of your food with wood-pressed oil! Here's why it's a kitchen game-changer:
          <br /> <br />
          <ul>
            <li>Flavor explosion: Ditch the bland! Wood-pressed oil brings out the natural richness of your ingredients. Think nutty sesame or vibrant olive oil – a symphony for your taste buds!</li>
            <li>Nutritional powerhouse: Cold-pressed and free of chemicals, wood-pressed oil retains more vitamins, antioxidants, and good fats –  a healthy boost for every dish.</li>
            <li>Pure and simple: No additives, no preservatives, just the goodness of the seeds or nuts themselves. Your body will thank you!</li>
            <li>Sustainable choice:  Support traditional methods that are gentle on the environment. Wood pressing feels good from farm to table.</li>
          </ul>
          <br />
          Ready to elevate your cooking? Look for wood-pressed oils – a taste you'll love and a healthy choice you can feel good about!
        </span>
      </section>
      <section className='section-product'>
        <span className='section-title'>Explore the World of Satvayug Oils</span>
        <div className='card-container'>
          <div className="row justify-content-center">
            {
              products ? (products.map((product, index) => (
                <ProductCard key={index} product={product} />
              ))) : null
            }
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
