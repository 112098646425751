// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import 'firebase/messaging'; // Import Firebase Messaging
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBuTbZ1ZNZkhuY6E9oNfsyQGJSO_xwWzW8",
  authDomain: "satvayug-fe4c5.firebaseapp.com",
  projectId: "satvayug-fe4c5",
  storageBucket: "satvayug-fe4c5.appspot.com",
  messagingSenderId: "70427248807",
  appId: "1:70427248807:web:9714dcdd28965765a8b382",
  measurementId: "G-EKKL67Y63D"
};

// Initialize Firebase
/*export const messaging = firebase.messaging();

export const requestNotificationPermission = async () => {
    try {
      await messaging.requestPermission();
      console.log('Notification permission granted.');
      // You can now use messaging.getToken() to get the user's device token
    } catch (error) {
      console.error('Unable to get permission to notify.', error);
    }
  };*/

initializeApp(firebaseConfig);

export const firebaseAuth = getAuth();
//export const firestore = getFirestore();
export const analytics = getAnalytics();