import React from 'react';
import '../styles/Products.css';
import { Footer, Navbar } from './Navbar';
import { apitoken, baseurl } from './base/base';

const Products = () => {
    const [data, setdata] = React.useState([]);
    const [cart, setCart] = React.useState([]);
    const [addedProducts, setAddedProducts] = React.useState([]);

    const fch = () => {
        fetch(baseurl + 'api/Product_Master/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + apitoken
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setdata(response);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                //setisRefreshing(false)
            });
    }

    const handleClick = (product) => {
        console.log(product);
        const newItem = {
            productid: product.id,
            producturl: baseurl + 'api/Product_Master/' + product.id,
            product: product,
            quantity: 1,
            orderid: ""
        };

        // Update the cart state by adding the new item
        setCart([...cart, newItem]);

        // Save the updated cart to local storage
        localStorage.setItem('cart', JSON.stringify([...cart, newItem]));

        // Update the list of added product IDs
        setAddedProducts([...addedProducts, product.id]);
    }

    React.useEffect(() => {
        fch();
    }, []);

    return (
        <div>
            <Navbar />
            <span className='product-page-heading'>Explore the World of Satvayug Oils</span>
            <div className='h3pcc'>
                <div className="row justify-content-center">
                    {data.map(product => (
                        <div className='product-card col-md-3' key={product.id}>
                            <img className='card-img' src={product.imageURL} alt={product.name} />
                            <div className='card-details'>
                                <div className='flexbox-row'>
                                    <span className='product-card-title'>{product.name}</span><span className='card-qty'>{product.qty}</span>
                                </div>
                                <span className='card-amount'>₹ {product.price}</span>
                                <button className='card-button' onClick={() => handleClick(product)} disabled={addedProducts.includes(product.id)}>
                                    {addedProducts.includes(product.id) ? "Added to Cart" : "Add to Cart"}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Products;