import React from 'react';
import '../styles/Products.css';

const OrderConf = () => {
    const [orderno, setOrderno] = React.useState();

    React.useEffect(() => {
        const x = sessionStorage.getItem('orderno');
        setOrderno(x);
    }, []);

    return(
        <div>
            <h3>Thanks for ordering with us!</h3>
            <h3>Your Order will be fulfilled shortly</h3>
            <p>You can track the order using your order number - <a href="/track">#{orderno}</a></p>
        </div>
    )
}

export default OrderConf;