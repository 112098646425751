import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import '../styles/App.css';
import '../styles/Contact.css';
import { Footer, Navbar } from './Navbar';

const About = () => {
    return (
        <div>
            <Navbar />
            <div className="row jei34">
                <div className="col-md-7">
                    <h3>Satvayug Oil: Honoring Tradition, Embracing Purity</h3>
                    <br/>
                    <p>
                        Satvayug Oil is a company dedicated to bringing the goodness of wood-pressed oils to your kitchen and your life. We believe in the power of natural ingredients and traditional methods to create products that are not only healthy but also bursting with flavor.
                    </p>
                    <h5>Our Story:</h5>
                    <p>
                        Our journey began with a desire to reconnect with simpler times, when food was pure and unadulterated. We rediscovered the ancient art of wood pressing, a method that gently extracts oil from seeds and nuts, preserving their natural nutrients and rich flavors.
                    </p>
                    <h5>Our Mission:</h5>
                    <p>
                        Our mission is to share this rediscovered treasure with you. We source the finest quality seeds and nuts, ensuring they are grown sustainably and ethically. We then use time-honored techniques to extract the purest oil, free from chemicals and solvents.
                    </p>
                    <h5>Our Promise:</h5>
                    <p>
                    When you choose Satvayug Oil, you're choosing more than just a cooking ingredient. You're choosing a commitment to quality, sustainability, and a taste of pure tradition. With every drop of our oil, you'll experience the difference that wood pressing makes.
                    </p>
                    <h5>Beyond the Kitchen:</h5>
                    <p>
                    Our commitment to purity extends beyond the kitchen. We strive to use eco-friendly and sustainable practices throughout our entire process, from sourcing our ingredients to packaging our products.
                    </p>
                    <h5>Join Us on Our Journey:</h5>
                    <p>
                    Explore our range of wood-pressed oils and discover the difference they can make in your cooking and your well-being. Let Satvayug Oil be your partner in creating delicious, healthy meals for you and your loved ones.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;