import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React, { useState } from 'react';
import '../styles/App.css';
import '../styles/Contact.css';
import { Navbar } from './Navbar';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('+91 ');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Process the submission here
        // For example, send the email and message to a server
        console.log('Email:', email);
        console.log('Message:', message);

        // Reset form fields
        setEmail('');
        setMessage('');
    };

    return (
        <div>
            <Navbar />
            <div className="row jei34">
                <div className="col-md-7">
                    <h1 className='contact-page-heading'>Contact Us</h1>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className='textbox'>
                            <label className='label' htmlFor="email">Name:</label>
                            <input
                                type="text"
                                id="name"
                                className='textfield'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='flexbox-row'>
                            <div className='textbox'>
                                <label className='label' htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    className='textfield'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='textbox'>
                                <label className='label' htmlFor="email">Phone:</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    className='textfield'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='textbox'>
                            <label className='label' htmlFor="message">Message:</label>
                            <textarea
                                id="message"
                                className='textfield textarea'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <button className="button" type="submit">Send</button>
                    </form>
                </div>
                <div className="col-md-5">
                    <div className="contact-details">
                        <span>Mail us at - </span>
                        <a href='mailto:help@satvayug.com'>help@satvayug.com</a>
                        <span>Get in touch for business related queries -</span>
                        <a href='mailto:business@satvayug.com'>business@satvayug.com</a>
                        <span>or Drop us a text on Whatsapp -</span>
                        <a href='https://api.whatsapp.com/send?phone=918591476976'>+91 85914 76976</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
