import React from 'react';
import '../styles/Products.css';
import { Footer, Navbar } from './Navbar';
import { apitoken, baseurl } from './base/base';

const Checkout = () => {
    const [cart, setCart] = React.useState([]);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [aadr, setaadr] = React.useState('');
    const [stamt, setstamt] = React.useState();
    const [discount, setdiscount] = React.useState(0);
    const [shipchg, setshipchg] = React.useState(70);
    const [orderid, setorderid] = React.useState();

    const fetchCartFromLocalStorage = () => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    };

    const formatCartForCheckout = (orderid) => {
        const formattedCart = cart.map(item => ({
            productid: item.productid,
            qty: item.quantity,
            orderid: orderid
        }));
        return formattedCart;
    };

    const generateOrder = (rzroid, txnid) => {
        alert(rzroid);
        return fetch(baseurl + 'api/order_master/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + apitoken
            },
            body: JSON.stringify({
                "customer_name": name,
                "customer_phone": '+91' + phone,
                "customer_email": email,
                "customer_addr": aadr,
                "city": "Mumbai",
                "payment_status": "paid",
                "txnid": txnid,
                "tamt": parseInt(stamt) + shipchg + discount,
                "t_products": 0,
                "order_shipped_status": "order placed",
                "razorpay_oid": rzroid,
            })
        })
            .then(response => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    console.error('Checkout failed! Error code:' + response.status);
                    throw new Error('Checkout failed');
                }
            })
            .then(resp => {
                setorderid(resp.id);
                console.warn(resp);
                return resp.id;
            })
            .catch(error => {
                console.error('Error:', error);
                throw error;
            });
    }

    const post_cart = (cartp, orderid) => {
        return fetch(baseurl + 'api/cart_item_bulk/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + apitoken
            },
            body: JSON.stringify(cartp)
        })
            .then(response => response.json())
            .then(resp => {
                console.warn(resp);
                if (resp.message == 'Cart items created successfully') {
                    sessionStorage.setItem('orderno', orderid);
                    localStorage.removeItem('cart');
                    window.location.href = '/order_confirmation';
                }
            })
            .catch(error => {
                console.error('Error:', error);
                throw error;
            });
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const rzrorder = () => {
        return fetch(baseurl + 'api/rzrorder/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Token ' + apitoken
            },
            body: JSON.stringify({
                "tamt": parseInt(stamt) + shipchg + discount,
            })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.error('Checkout failed! Error code:' + response.status);
                    throw new Error('Checkout failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                throw error;
            });
    }

    const checkout = () => {
        rzrorder()
            .then(response => {
                loadScript('https://checkout.razorpay.com/v1/checkout.js').then((res) => {
                    if (!res) {
                        //alert("Razorpay SDK failed to load. Are you online?");
                        console.error("Razorpay SDK failed to load");
                        return;
                    }
                    var options = {
                        "key": "rzp_live_R5EmqaD00mbAgR", // Enter the Key ID generated from the Dashboard
                        "amount": parseInt(stamt) + shipchg + discount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                        "currency": "INR",
                        "name": "Satvayug", //your business name
                        "description": "Satvayug",
                        "image": "https://satvayug.com/static/media/logo_navbar.f84404938a9ea775cb31f968ac5e10cb.svg",
                        "order_id": response.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        "handler": function (response) {
                            //alert(response.razorpay_payment_id);
                            //alert(response.razorpay_order_id);
                            //alert(response.razorpay_signature);
                            generateOrder(response.razorpay_order_id, response.razorpay_payment_id)
                                .then(orderid => {
                                    if (orderid !== -1) {
                                        fetchCartFromLocalStorage();
                                        const formattedCart = formatCartForCheckout(orderid);
                                        post_cart(formattedCart, orderid);
                                    }
                                })
                                .catch(error => {
                                    // Handle error
                                    console.error(error);
                                });
                        },
                        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                            "name": name, //your customer's name
                            "email": email,
                            "contact": phone  //Provide the customer's phone number for better conversion rates 
                        },
                        "notes": {
                            "address": "Satvayug Corporate Office"
                        },
                        "theme": {
                            "color": "#3399cc"
                        }
                    };
                    var rzp1 = new window.Razorpay(options);
                    rzp1.on('payment.failed', function (response) {
                        /*alert(response.error.code);
                        alert(response.error.description);
                        alert(response.error.source);
                        alert(response.error.step);
                        alert(response.error.reason);
                        alert(response.error.metadata.order_id);
                        alert(response.error.metadata.payment_id);*/
                        alert("Payment was Unsuccessful, Please try again")
                        window.location.href = 'checkout/';
                    });
                    rzp1.open();
                })
            })
    }

    React.useEffect(() => {
        fetchCartFromLocalStorage();
        const x = sessionStorage.getItem('stamt');
        setstamt(x);
    }, []);

    return (
        <div>
            <Navbar />
            <div className="row jei34">
                <div className="col-md-8">
                    <span className='contact-page-heading'>Details</span>
                    <form className="contact-form">
                        <div className='textbox'>
                            <label className='label' htmlFor="email">Name:</label>
                            <input
                                type="text"
                                id="name"
                                className='textfield'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='flexbox-row'>
                            <div className='textbox'>
                                <label className='label' htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    className='textfield'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='textbox'>
                                <label className='label' htmlFor="email">Phone:</label>
                                <div style={{ display: 'flex' }} className='textfield'>
                                    <span>+91</span>
                                    <input
                                        type="tel"
                                        id="phone"
                                        className='textfield'
                                        value={phone}
                                        maxlength="10"
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                        style={{
                                            marginLeft: 4,
                                            padding: 0,
                                            border: 'none',
                                            outline: 'none',
                                            display: 'inline-block'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='textbox'>
                            <label className='label' htmlFor="message">Address:</label>
                            <textarea
                                id="message"
                                className='textfield textarea'
                                value={aadr}
                                onChange={(e) => setaadr(e.target.value)}
                                required
                            ></textarea>
                        </div>
                    </form>
                </div>
                <div className="col-md-4">
                    <div className='summary-container'>
                        <div>
                            <span className='summary-text'>Summary</span>
                            <div className='total-amt-container'>
                                <span>Total</span>
                                <span className='total-amt'>₹ {parseInt(stamt) + shipchg + discount}</span>
                            </div>
                            <div className='sub-total-amt-container'>
                                <span>Subtotal</span>
                                <span className='sub-total-amt'>₹ {stamt}</span>
                            </div>
                            <div className='discount-amt-container'>
                                <span>Shipping Charges</span>
                                <span className='discount-amt'>₹ {shipchg}</span>
                            </div>
                            <div className='discount-amt-container'>
                                <span>Discount</span>
                                <span className='discount-amt'>₹ {discount}</span>
                            </div>
                        </div>
                        <button className='button checkout-button' onClick={() => checkout()}>CheckOut</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Checkout;