import React from 'react';
import '../styles/Products.css';
import { Footer, Navbar } from './Navbar';

const Cart = () => {
    const [cart, setCart] = React.useState([]);
    const [tamt, settamt] = React.useState();

    const removeFromCart = (productId) => {
        const updatedCart = cart.filter(item => item.productid !== productId);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const increaseQuantity = (productId) => {
        const updatedCart = cart.map(item => {
            if (item.productid == productId) {
                return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    // Function to decrease the quantity of an item in the cart
    const decreaseQuantity = (productId) => {
        const updatedCart = cart.map(item => {
            if (item.productid === productId && item.quantity > 1) {
                return { ...item, quantity: item.quantity - 1 };
            }
            return item;
        });
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));

    };

    const fetchCartFromLocalStorage = () => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    };

    const calculateTotalPrice = () => {
        return cart.reduce((total, item) => total + (item.product.price * item.quantity), 0);
    };

    const cart_checkout = () => {
        settamt(calculateTotalPrice());
        const x = calculateTotalPrice();
        sessionStorage.setItem('stamt', x);
        window.location.href = 'checkout';
    };

    React.useEffect(() => {
        fetchCartFromLocalStorage();        
    }, []);

    return (
        <div>
            <Navbar />
            <div className='h3pcc'>
                <div className='flexbox-row hu58'>
                    <span className='cart-page-heading'>Cart</span>
                    <div className='lei2'>
                        <span>Subtotal Amount - ₹ {cart.reduce((total, item) => total + (item.product.price * item.quantity), 0)}</span>
                        <button className='button cart-checkout-button' onClick={() => cart_checkout()}>CheckOut</button>
                    </div>
                </div>
                <div className="row">
                    {cart.map((item, index) => (
                        <div className='product-card col-md-3' key={index}>
                            <img className='card-img' src={item.product.imageURL} alt={item.product.name} />
                            <div className='card-details'>
                                <div className='flexbox-row'>
                                    <span className='product-card-title'>{item.product.name}</span><span className='card-qty'>{item.product.qty}</span>
                                </div>
                                <span className='card-amount'>₹ {item.product.price * item.quantity}</span>
                                <div className='plus-minus-parent'>
                                    <button className='cart-button' onClick={() => decreaseQuantity(item.productid)}>-</button>
                                    <span>{item.quantity}</span>
                                    <button className='cart-button' onClick={() => increaseQuantity(item.productid)}>+</button>
                                </div>
                                <button className='cart-button remove-button' onClick={() => removeFromCart(item.productid)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cart;