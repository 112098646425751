
export const baseurl = 'https://api.satvayug.com/';
export const firebaseurl = 'https://firestore.googleapis.com/v1/';
export const fireabaseapiurl = firebaseurl + 'projects/satvayug-fe4c5/databases/(default)/documents'
export const devbaseurl = 'http://localhost:8000/';
//const apiurl = 'http://localhost:8000/api/HomePage_Products/';
export const apitoken = 'dcd31a20ffb5f6ee0b835297c81d403e3233b036';
export const devapitoken = 'b9b4ac8a5ba356335e01278d22d2f5a337b15e9b';


const userAgent = navigator.userAgent;

// Extract the first priority browser name
let browserName = '';

if (userAgent.match(/Chrome/)) {
    browserName = 'Chrome';
} else if (userAgent.match(/Safari/)) {
    browserName = 'Safari';
} else if (userAgent.match(/Firefox/)) {
    browserName = 'Firefox';
} else {
    browserName = 'Unknown';
}

export const browserInfo = browserName;

// Extract operating system
let operatingSystem = 'Unknown';
if (userAgent.match(/Windows NT 10.0/)) {
  operatingSystem = 'Windows 10';
} else if (userAgent.match(/Windows NT 6.2/)) {
  operatingSystem = 'Windows 8';
} else if (userAgent.match(/Windows NT 6.1/)) {
  operatingSystem = 'Windows 7';
} else if (userAgent.match(/Windows NT 6.0/)) {
  operatingSystem = 'Windows Vista';
} else if (userAgent.match(/Windows NT 5.1|Windows XP/)) {
  operatingSystem = 'Windows XP';
} else if (userAgent.match(/Macintosh|Mac OS/)) {
  operatingSystem = 'Mac OS';
} else if (userAgent.match(/Linux/)) {
  operatingSystem = 'Linux';
} else if (userAgent.match(/Android/)) {
  operatingSystem = 'Android';
} else if (userAgent.match(/iPhone|iPad|iPod/)) {
  operatingSystem = 'iOS';
}

export const device_os = operatingSystem;


/*
export const getUniqueId = () => {
  let uniqueId = Cookies.get('uniqueId');
  if (!uniqueId) {
    uniqueId = generateUniqueId();
    Cookies.set('uniqueId', uniqueId, { expires: 365 }); // Set cookie expiration to 1 year
  }
  return uniqueId;
};

const generateUniqueId = () => {
  return Math.random().toString(36).substring(2);
};*/
