import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import './App.css';
import About from './components/About';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import ContactPage from './components/Contact';
import Home from './components/Home';
import OrderConf from './components/OrderPlaces';
import PrivacyPolicy from './components/PrivayPolicy';
import Products from './components/Products';
import ReturnPolicy from './components/ReturnPolicy';
import Terms from './components/Terms';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/order_confirmation" element={<OrderConf />} />
          <Route path="/track" element={<OrderConf />} />
          <Route path="/policies/return" element={<ReturnPolicy />} />
          <Route path="/policies/terms" element={<Terms />} />
          <Route path="/policies/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
