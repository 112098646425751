import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import '../styles/App.css';
import '../styles/Contact.css';
import { Footer, Navbar } from './Navbar';

const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="row jei34">
                <div className="col-md-7">
                    <h1 className='contact-page-heading'>Privacy Policy</h1>
                    <br />
                    <div>
                        <p>Last updated on Jun 18 2024</p>
                        <h5>Introduction</h5>
                        <p>Welcome to SATVAYUG AGRO FARM PRIVATE LIMITED ("Company", "we", "our", "us"). We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://satvayug.com (the "Site"), and purchase our products.</p>
                        <h5>Information We Collect</h5>
                        <p>We may collect and process the following types of personal data:</p>
                        <ol>
                            <li className='jei35'>Personal Identification Information:
                                <ul>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Phone number</li>
                                    <li>Address</li>
                                </ul>
                            </li>
                            <li className='jei35'>Payment Information:
                                <ul>
                                    Credit card details
                                    Billing address
                                    Transaction details
                                </ul>
                            </li>
                            <li className='jei35'>Technical Data:
                                <ul>
                                    IP address
                                    Browser type and version
                                    Time zone setting
                                    Browser plug-in types and versions
                                    Operating system and platform
                                    Other technology on the devices you use to access this website
                                </ul>
                            </li>
                            <li className='jei35'>
                                Usage Data:
                                <ul>
                                    Information about how you use our website, products, and services.
                                </ul>
                            </li>
                            <li className='jei35'>
                                Marketing and Communications Data:
                                <ul>
                                    Your preferences in receiving marketing from us and your communication preferences.
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default PrivacyPolicy;